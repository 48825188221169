const personal =  {
  "code": "user",
  "name": "用户中心",
  icon:'ac-icon-a-app07',
  "childMenus": [
      {
    "code": "user1",
    "name": "个人资料",
    "parentCode": "report",
    "url": "application/user/personal-infor",
    "childMenus": []
  }, {
    "code": "user2",
    "name": "账号与安全",
    "parentCode": "report",
    "url": "application/user/personal-security",
    "childMenus": []
  },
  {
    "code": "user3",
    "name": "身份认证",
    "parentCode": "report",
    "url": "application/user/personal-auth",
    "childMenus": []
  }
]
}

  const company = {
    "code": "user",
    "name": "用户中心",
    icon:'ac-icon-a-app07',
    "childMenus": [
        {
      "code": "user1",
      "name": "公司信息",
      "parentCode": "report",
      "url": "application/user/company",
      "icon": 'ac-icon-icon-office1',
      "childMenus": []
    }, {
      "code": "user2",
      "name": "用户管理",
      "parentCode": "report",
      "url": "application/user/company-info",
      "icon": "ac-icon-icon-binding-user-group1",
      "childMenus": []
    },
    {
      "code": "user3",
      "name": "账号与安全",
      "parentCode": "report",
      "url": "application/user/company-security",
      "icon": "ac-icon-icon-authority1",
      "childMenus": []
    }
  ]
  }
  export {
    personal,
    company
  }