/**++----------------------------------------------------------------------------------------------------------------------------------------------------------------------
1.功能：
2.作者：Object
-----------------------------------------------------------------------------------------------------------------------------------------------------------------------++*/

import { Vue , VueRouter , store , localRead,localClear,publicStartPath,dataCommonInsert,sessionSave} from 'feSystemHub'
import { Message} from 'element-ui';
import {apiDataFilter}  from '../utils/apiDataFilter'
import devMenu from '../router/menu'
import {personal,company} from './userMenu'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/application/*',
    name: 'layout',
    component: () => import(/* webpackChunkName: "application" */ '../views/layout')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/sso',
    name: 'Sso',
    component: () => import(/* webpackChunkName: "login" */ '../views/Sso.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue')
  },
  {
    path: '/',
    redirect: { path: '/application/workbench/home' }
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const whiteList = ['/login', '/sso', '/logout']
router.beforeEach((to, from, next) => {
  let token = localRead('token')
  let userInfo = store.state.portal.userInfo
  if (token) {
    if (userInfo.userId) {
      // 用户中心个人端不需要加载菜单
      if(userInfo.loginType === 'personal') {
        next()
      }else {
        loadMenu(to, from, next)
      }
    } else {
      // 用户中心获取token后 会立即获取用户信息，直接跳过
      if(to.path.indexOf('/application/user/')>-1) {
        next()
      }else {
        store.dispatch('setUserInfo').then(()=>{
          loadMenu(to, from, next)
        }).catch(()=>{
          localClear()
        })
      }
    }
  } else {
    if (whiteList.includes(to.path) || to.path.indexOf('/application/user/authed')>-1) {
      /*loadMenu(to, from, next)*/
      next()
    } else {
      let redirect_uri = window.location.href
      window.location.href = `/login?redirect_uri=${encodeURIComponent(redirect_uri)}`
    }
  }
})


/**
 * 获取菜单
 * @param to
 * @param from
 * @param next
 */
const loadMenu = (to, from, next) => {
 // let loadingInstance = Loading.service({fullscreen: true});
  let userInfo = store.state.portal.userInfo
  if (store.getters.menus.length === 0) {
    if (process.env.NODE_ENV === 'development') {
      /* store.dispatch('setMenu', devMenu).then(() => {
        if (to.path === '/login') {
          next({path: publicStartPath, replace: true}) // hack方法 确保addRoutes已完成
        } else {
          next({...to, replace: true}) // hack方法 确保addRoutes已完成
        }
      }) */
      if(userInfo.loginType === 'enterprise') {
        loadUserCenterMenu(to,from,next)
        return
      }
      apiDataFilter.request({
        api:'portal.menu',
        method:'post',
        data:dataCommonInsert(null,1)
      }).then((res)=>{
        let result = res.data||[]
        store.dispatch('setMenu',result).then(()=>{
          if (to.path === '/login'){
            next({ path:'/', replace: true }) // hack方法 确保addRoutes已完成
          }else {
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          }
        }).catch(()=>{
          Message.warning('对不起，您没有菜单权限登录此系统！')
          localStorage.clear()
          // next('/login')
          // 清除store中的数据，避免使用之前登录的用户信息
          window.location.href = '/login'
        })
      }).finally(()=>{
        // loadingInstance.close()
      })

    } else {
      if(userInfo.loginType === 'enterprise') {
        loadUserCenterMenu(to,from,next)
        return
      }
      apiDataFilter.request({
        api:'portal.menu',
        method:'post',
        data:dataCommonInsert(null,1)
      }).then((res)=>{
        let result = res.data ||[]
        store.dispatch('setMenu',result).then(()=>{
          if (to.path === '/login'){
            next({ path:'/', replace: true }) // hack方法 确保addRoutes已完成
          }else {
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          }
        }).catch(()=>{
          Message.warning('对不起，您没有权限登录此系统！')
          localStorage.clear()
          // next('/login')
          // 清除store中的数据，避免使用之前登录的用户信息
          window.location.href = '/login'
        })
      }).finally(()=>{
        /*  loadingInstance.close()*/
      })
    }
  } else {
    //loadingInstance.close()
    next()
  }
}
const loadUserCenterMenu = (to,from,next)=> {
  let result = []
  store.commit('SET_NAV_TAG_LIST',[])
  result.push(company)
  store.dispatch('setCurrentMenuIndex',0)
  store.dispatch('setMenu',result).then(()=>{
      if (to.path === '/login'){
        next({ path:'/', replace: true }) // hack方法 确保addRoutes已完成
      }else {
        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
      }
    }).catch(()=>{
    Message.warning('对不起，您没有菜单权限登录此系统！')
    localStorage.clear()
    // next('/login')
    // 清除store中的数据，避免使用之前登录的用户信息
    window.location.href = '/login'
  })
}
export default router
