/**++----------------------------------------------------------------------------------------------------------------------------------------------------------------------
 1.功能：主应用
 2.作者：Object
 -----------------------------------------------------------------------------------------------------------------------------------------------------------------------++*/

import {store, lodash, sessionRead, sessionSave,localSave,localRead} from 'feSystemHub'
import {apiDataFilter} from '../utils/apiDataFilter'
import appRouters from '../router/micro'
import config from '../config'
store.registerModule('portal', {
    state: {
        menu: [],
        userInfo: localRead('userInfo')||{},
        navTagList: [{ "path": "/home", "name": "home", "component": "home/index", "meta": { "title": "工作台" }, "fullPath": "/application/workbench/home", "menuPath": "/application/workbench/home" } ],
        currentNav: '',
        menuProps: {
            activePath: '',
            collapse: false
        },
        currentTopMenuIndex: sessionRead('currentTopMenuIndex') || 0,
        routerList:[],
        roleList:[],
        keepAliveComponents: [],
        // SSO个人端登录
        personalUrl: config.personalUrl,
        // SSO企业端登录
        enterpriseUrl: config.enterpriseUrl
    },
    mutations: {
        SET_MENU(state, menu) {
            state.menu = [...state.menu, ...menu]
        },
        SET_USERINFO(state, info) {
            state.userInfo = info
            localSave('userInfo',info)
        },
        CURRENT_NAV(state,path){
            state.currentNav = path
        },
        SET_NAV_TAG_LIST(state, navTagList) {
            state.navTagList = [...navTagList]
            this.commit('SET_ALIVE_COMPONENTS')
        },
        ACTIVE_PATH(state, activePath) {
            let {menuProps} = state
            state.menuProps = {
                ...menuProps,
                activePath
            }
        },
        COLLAPSE_MENU(state,collapse) {
            state.menuProps = {
                ...state.menuProps,
                collapse,
            }
        },
        REMOVE_NAV_TAG(state, navTagList) {
            state.navTagList = [...navTagList]
            this.commit('SET_ALIVE_COMPONENTS')
        },
        setCurrentTopMenuIndex (state, index) {
            state.currentTopMenuIndex = index
        },
        SET_ROUTER_LIST(state,list){
            state.routerList = list
        },
        SET_ROLE_LIST(state,list) {
            state.roleList = list
        },
        SET_ALIVE_COMPONENTS(state) {
            let aliveComponents = []
            state.navTagList.forEach(item=>{
                if(!aliveComponents.includes(item.name)) {
                    aliveComponents.push(item.name)
                }
            })
            state.keepAliveComponents = aliveComponents
        }

    },
    actions: {
        setMenu({ commit },routers){
            return new Promise((resolve, reject)=>{
                if (routers.length > 0){
              /*      apiDataFilter.request({
                        api:'common.buttonList',
                    }).then((res)=>{
                        commit('SET_FUNLIST',res.data)

                    })*/
                    commit('SET_MENU',routers)
                    resolve();
                }else {
                    reject();
                }
            })
        },
        setUserInfo({commit,dispatch}) {
            return new Promise((resolve, reject) => {
                apiDataFilter.request({
                    api: 'portal.userInfo',
                    method:'post'
                }).then((res) => {
                    let result = res.data;
                    commit('SET_USERINFO', result)
                    dispatch('setRoleList',result)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        setNavTagList({ commit, state },router){
            return new Promise((resolve)=>{
                const  {fullPath,menuPath} = router
                const navTagList = [...state.navTagList]
                if (navTagList.length) {
                    if (!navTagList.map(item => item.fullPath).includes(fullPath)) {
                        navTagList.push({
                            ...router
                        })
                    }
                } else {
                    navTagList.push({
                        ...router
                    })
                }
                commit('SET_NAV_TAG_LIST',navTagList)
                commit('CURRENT_NAV',fullPath)
                commit('ACTIVE_PATH',menuPath)
                resolve();
            })
        },
        removeNavTag({ commit, state },removeNavPath){
            return new Promise((resolve)=>{
                let {navTagList, currentNav} = state
                navTagList = lodash.remove(navTagList, item => item.fullPath !== removeNavPath);
                if (navTagList.length) {
                    let [{fullPath: lastNav}] = navTagList.slice(-1)
                    if (removeNavPath === currentNav) {
                        commit('CURRENT_NAV',lastNav)
                    }
                }
               /* localSave('navTagList', navTagList)*/
                commit('REMOVE_NAV_TAG',navTagList)
                resolve();
            })
        },
        setCollapseMenu({ commit },collapse){
            commit('COLLAPSE_MENU',collapse)
        },
        setCurrentMenuIndex ({ commit },index) {
            return new Promise((resolve)=>{
                sessionSave('currentTopMenuIndex', index)
                commit('setCurrentTopMenuIndex',index)
                resolve()
            })
        },
        setRouterList({commit},list){
            return new Promise((resolve)=>{
                commit('SET_ROUTER_LIST',list)
                resolve()
            })
        },
        setDevRouterList({commit}, {pathPrefix='',list=[]}){
            if (pathPrefix && list.length > 0) {
                let index = appRouters.findIndex((item) => item.path == pathPrefix)
                if (index > -1) {
                    appRouters[index].children = list
                }

                commit('SET_ROUTER_LIST', appRouters)
            }
        },
        setRoleList({commit},user) {
            return new Promise((resolve, reject) => {
                apiDataFilter.request({
                    api: 'portal.roleList',
                    method:'post',
                    data:{id:user.userId},
                    type:2
                }).then((res) => {
                    console.log(res)
                    commit('SET_ROLE_LIST',res.data)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        setActivePath({commit},activePath){
            return new Promise((resolve)=>{
                commit('ACTIVE_PATH',activePath)
                resolve()
            })
        },
        setUserCenterUser({commit,dispatch},item={}) {
            return new Promise((resolve, reject) => {
                apiDataFilter.request({
                    api: 'portal.getUser',
                    method:'post',
                    data: {id:item.id}
                }).then((res) => {
                    let result = res.data;
                    result.userId = result.id
                    result.userType = 1
                    // 判断个人登录还是企业账号登录
                    result.loginType = item.loginType || (localRead('userInfo')||{loginType:'personal'}).loginType
                    commit('SET_USERINFO', result)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        }
    },
    getters: {
        menus: state => state.menu,
        menuProps: state => state.menuProps,
        currentTopMenuIndex: state => state.currentTopMenuIndex,
        routerList:(state)=>state.routerList
    }
})

