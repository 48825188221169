/**++----------------------------------------------------------------------------------------------------------------------------------------------------------------------
1.功能：portal 主应用接口
2.作者：Object
-----------------------------------------------------------------------------------------------------------------------------------------------------------------------++*/

const noNeedApiPrefix = {
    menu:'authority-service/currentMenu'
}

const needApiPrefix = {
    login: 'basic-api/user/login',
    userInfo: 'basic-api/user/my/getCurrentUser',
    roleList:'basic-api/user/my/myRoles',
    userLoginAuth: 'basic-api/users/auth-pwd',
    getImgCode: 'basic-api/users/verification-pic',
    getCode: 'basic-api/users/verification-code',
    userLogin: 'basic-api/users/login',
    enterpriseLogin: 'users/login-enterprise',
    eLogin: 'users/auth-enterprise',
    getUser: 'basic-api/users/common/detail',
    querySso: 'basic-api/users/sso-callback',
    logout: 'basic-api/user/my/signOut', // 底座退出登录
    usercenterOut: 'basic-api/users/common/signOut' // 用户中心退出登录
}


const dealApiPath = (obj={},apiPrefix='')=>{
    for (let key in obj){
        if (typeof obj[key] === 'string'){
            obj[key] = apiPrefix + obj[key]
        }else {
            dealApiPath(obj[key],apiPrefix)
        }
    }
    return obj
}

export default {
    portal:{
        ...noNeedApiPrefix,
        ...dealApiPath(needApiPrefix,'service/') // basic-api/
    }
}





