<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
    data(){
      return{
      }
    },
    created() {

    },
    methods:{

    }
}

</script>

<style lang="less">

</style>
