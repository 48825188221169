/**++----------------------------------------------------------------------------------------------------------------------------------------------------------------------
1.功能：portal 主入口
2.作者：Object
-----------------------------------------------------------------------------------------------------------------------------------------------------------------------++*/

import { Vue , i18n , store} from 'feSystemHub'
import App from './App.vue'
import router from './router'
import './store'
import Langs from './i18n'
import {apiDataFilter}  from './utils/apiDataFilter'

Vue.config.productionTip = false
Vue.prototype.$apiDataFilter = apiDataFilter

Object.keys(Langs).forEach((item)=>{
  i18n.mergeLocaleMessage(item,Langs[item])
})

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')


