/**++----------------------------------------------------------------------------------------------------------------------------------------------------------------------
1.功能：
2.作者：Object
-----------------------------------------------------------------------------------------------------------------------------------------------------------------------++*/

const zh = {
    example:'翻着来好的刷卡就好喀什撒',
    menu: {
        home_page: '首页',
        basic_center: '基础中心',
        system_management: '系统管理',
        metadata_management: '元数据管理',
        value_set_management: '值集管理',
        numbering_rule_management: '编号规则管理',
        system_setting_management: '系统设定',
        date_definition_management: '日期定义',
        event_definition_management: '事件定义',
        reporting_tool: '报表工具',
        workflow_designer: '工作流设计器',
        access_management: '权限管理',
        access_object_management: '权限对象管理',
        form_object_file: '表单对象档案',
        navigation_menu_configuration: '导航菜单配置',
        permission_role_file: '权限角色档案',
        user_file: '用户档案',
        master_data_center: '主数据中心',
        product_management: '产品管理',
        brand_management: '品牌管理',
        product_dynamic_attribute: '产品动态属性',
        product_attribute_configuration: '产品属性配置',
        product_file: '产品档案',
        category_management: '类目管理',
        background_category_management: '后台类目管理',
        foreground_category_management: '前台类目管理',
        background_category_tree_management: '后台类目树管理',
        foreground_category_tree_management: '前台类目树管理',
        foreground_and_background_category_mapping: '前后台类目映射',
        supplier: '供应商',
        navigation_supplier: '导航供应商',
        supplier_attribute_configuration: '供应商属性配置',
        supplier_staff_management: '供应商员工管理',
        supplier_distribution: '供应商分配',
        supplier_dynamic_attribute: '供应商动态属性',
        customer_management: '客户管理',
        customer_file_management: '客户档案管理',
        customer_staff_management: '客户员工管理',
        customer_distribution_management: '客户分配管理',
        customer_dynamic_attribute: '客户动态属性',
        customer_attribute_configuration: '客户属性配置',
        'omni-channel_organizational_management': '全渠道组织管理',
        store_management: '店铺管理',
        staff_management: '员工管理',
        organization_file_management: '组织档案管理',
        channel_file_management: '渠道档案管理',
        platform_management: '平台管理',
        organization_tree: '组织架构树',
        'omni-channel_tree': '全渠道树管理',
        store_dynamic_attribute: '店铺动态管理',
        store_dynamic_configuration: '店铺属性配置',
        staff_dynamic_attribute: '员工动态属性',
        staff_dynamic_configuration: '员工属性配置',
        organization_dynamic_attribute: '组织动态属性',
        organization_dynamic_configuration: '组织属性配置',
        price_management: '价格管理',
        valuation_dimension_management: '计价纬度管理',
        price_item_management: '价格项管理',
        pricing_management: '定价管理',
        fetch_type_query: '取价类型查询',
        product_price: '单品价格',
        storage_management: '仓库管理',
        location_file_management: '库位档案管理',
        storage_dynamic_attribute: '仓库动态属性',
        storage_dynamic_configuration: '仓库属性配置',
        address_management: '地址管理',
        nation_file_management: '国家档案管理',
        province_file_management: '省份档案管理',
        city_file_management: '城市档案管理',
        county_file_management: '区县档案管理',
        township_and_street_file_management: '乡镇街道档案管理',
        supply_chain_management: '物流公司管理',
        capability_file: '能力档案',
        base_calendar: '基准日历',
        menber_center: '会员中心',
        member_list: '会员列表',
        member_label: '会员标签',
        member_file: '会员档案',
        member_registration: '会员注册',
        member_attribute_configuration: '会员属性配置',
        member_level: '会员等级',
        member_organization_configuration: '会员组织配置',
        member_benefit_charge: '会员福利费用',
        member_points: '会员积分',
        cumulative_rule_setting: '累加规则设定',
        accumulative_activity: '累加活动',
        clear_rule_setting: '清除规则设定',
        integral_behavior_statement: '积分行为流水',
        manual_adjustment_sheet: '手工调整单',
        exchange_rule_setting: '兑换规则设定',
        register_channel: '注册渠道',
        stock_center: '库存中心',
        storages_management: '库存管理',
        available_storage: '可用库存',
        object_storage: '实物仓库存',
        logical_storage: '逻辑仓库存',
        channel_storage: '渠道仓库存',
        sharing_storage: '共享库存',
        storage_synchronization: '库存同步',
        transferring_order_management: '调拨单管理',
        delivery_order_management: '出库单管理',
        godown_entry_management: '入库单管理',
        order_center: '订单中心',
        order_management: '订单管理',
        retail_order_management: '零售订单管理',
        retail_delivery_management: '零售出库管理',
        retail_return_management: '零售退货管理',
        retail_godown_entry_management: '零售入库单管理',
        sales_delivery_notice: '销售出库通知单',
        order_routing: '订单路由',
        sales_delivery_order: '销售出库单',
        retail_order_enquiry: '零售订单查询',
        sales_godown_entry_notice: '销售入库通知单',
        sales_godown_entry: '销售入库单',
        retail_return_order: '零售退货订单',
        workorder_management: '工单管理',
        marketing_center: '营销中心',
        promotion_management: '促销管理',
        promotion_theme: '促销主题',
        promotion_order: '促销单',
        promotion_rule: '促销规则',
        promotion_activity_management: '促销活动管理',
        marketing_campaign: '营销活动',
        marketing_campaign_management: '营销活动管理',
        coupon_management: '卡券管理',
        coupon_storage: '卡券入库',
        coupon_receive: '卡券领用',
        coupon_type_file_management: '卡券类别档案管理',
        coupon_use_rule: '卡券使用规则',
        coupon_issue_rule: '卡券发放规则',
        coupon_information: '卡券信息',
        coupon_activity_sheet: '卡券活动单',
        coupon_segment_query: '卡券号段查询',
        value_card_recharge: '储值卡充值',
        coupon_issue_query: '卡券发放查询',
        coupon_type: '卡券类别',
        coupon_file: '礼券档案',
        shopping_card_file: '购物卡档案',
        coupon_creation: '卡券创作',
        value_card_issue_query: '储值卡发放查询',
        recharge_activity: '充值活动',
        value_card_recharge_query: '储值卡充值查询',
        value_card_return: '储值卡退卡',
        purchasing_center: '采购中心',
        purchase_management: '采购管理',
        purchase_demand_management: '采购需求管理',
        purchase_order_management: '采购订单管理',
        purchase_storage_notice_management: '采购入库通知单管理',
        purchase_storage: '采购入库',
        purchase_return_order_manegement: '采购退货订单管理',
        purchase_delivery_notice_management: '采购出库通知单管理',
        purchase_delivery: '采购出库单',
        message_center: '消息中心',
        message_management: '消息管理',
        content_template_query: '内容模板查询',
        message_query: '消息查询',
        notice_query: '通知查询',
        notification_letter_query: '通知函查询',
        contacts_customized_query: '联系人自定义组合查询',
        notice_reply: '通知回复',
        customer_notice: '用户通知',
        message_list: '消息列表',
        notice_list: '通知列表',
        notification_letter_list: '通知函列表',
        artificial_intelligence: '人工智能',
        customer_portrait: '消费者画像',
        experimental_result: '实验结果',
        AI_scene_selection: 'AI场景选择',
        consumer_data_label: '消费数据标签',
        consumer_group_label: '消费群标签',
        scene_file: '场景档案',
        algorithm_file: '算法档案',
        marketing_middle_platform: '营销中台',
        flow_monitoring: '流量监测',
        flow_overview: '流量概览',
        users_retention: '用户留存',
        share_spread: '分享传播',
        sharing_user: '分享用户',
        transmission_relationship: '传播关系',
        user_track: '用户追踪',
        user_portrait: '用户画像',
        user_reach: '用户触达',
        CDP: 'CDP',
        CDP_flow_monitoring: '流量监测',
        CDP_users_retention: '用户留存',
        CDP_transmission_user: '传播用户',
        CDP_transmission_relationship: '传播关系',
        CDP_user_portrait: '用户画像',
        CDP_user_reach: '用户触达',
        AI_lab: 'AI实验室',
        CDP_customer_portrait: '消费者画像',
        CDP_AI_scene_selection: 'AI场景选择',
        CDP_experimental_result: '实验结果',
        CDP_consumer_data_label: '消费数据标签',
        CDP_consumer_group_label: '消费群标签',
        CDP_scene_file: '场景档案',
        CDP_algorithm_file: '算法档案',
        supplier_portal: '供应商门户'
    },
    login:{
        out:'退出登录'
    },
    portal:{
        tabs:{
            right:'关闭右侧',
            left:'关闭左侧',
            other:'关闭其它'
        },
        collapse:'收起菜单'
    }
}
export default zh
