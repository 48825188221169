/**++----------------------------------------------------------------------------------------------------------------------------------------------------------------------
1.功能：
2.作者：Object
-----------------------------------------------------------------------------------------------------------------------------------------------------------------------++*/


export default [
    {
        name:'user',
        entry:'//localhost:8081/fe_user_center/',
        path:'/application/user',
        defaultRegister:'',
        children:[],
    },
    {
        name:'knowledge',
        entry:'//localhost:8069/fe_knowledge_center/',
        path:'/application/knowledge',
        defaultRegister:'',
        children:[],
    },
    {
        name:'vm',
        entry:'//localhost:8060/fe_vm_center/',
        path:'/application/vm',
        defaultRegister:'',
        children:[],
    },
    {
        name:'workbench',
        entry:'//localhost:8079/fe_workbench_center/',
        path:'/application/workbench',
        defaultRegister:'',
        children:[],
    },
    {
        name:'sheet',
        entry:'//localhost:8078/fe_sheet_center/',
        path:'/application/sheet',
        defaultRegister:'',
        children:[],
    },
    {
        name:'print',
        entry:'//localhost:8077/fe_print_center/',
        path:'/application/print',
        defaultRegister:'',
        children:[],
    },
    {
        name:'file',
        entry:'//localhost:8076/fe_file_system/',
        path:'/application/file',
        defaultRegister:'',
        children:[],
    },
    {
        name:'organization',
        entry:'//localhost:8075/fe_organization_center/',
        path:'/application/organization',
        defaultRegister:'',
        children:[],
    },
    {
        name:'message',
        entry:'//localhost:8074/fe_message_center/',
        path:'/application/message',
        defaultRegister:'',
        children:[],
    },
    {
        name:'form',
        entry:'//localhost:8073/fe_form_design/',
        path:'/application/form',
        defaultRegister:'',
        children:[],
    },
    {
        name:'system',
        entry:'//localhost:8072/fe_system_manage/',
        path:'/application/system',
        defaultRegister:'',
        children:[],
    },
    {
        name:'workflow',
        entry:'//localhost:8071/fe_workflow/',
        path:'/application/workflow',
        defaultRegister:'',
        children:[],
    },
    {
        name:'demo',
        entry:'//localhost:8088/fe_example/',
        path:'/application/demo',
        defaultRegister:'',
        children:[],
    },
    {
        name:'report',
        entry:'//localhost:8070/fe_report_form/',
        path:'/application/report',
        defaultRegister:'',
        children:[],
    },

]
