export default {
  personalUrl: getPersonalUrl(),
  enterpriseUrl: getEnterPriseUrl(),
}


function getPersonalUrl() {
  // DEV重定向地址
  let redirect_uri =  'http://ac.cloud-platform-dev.web/sso'
  // DEV SSO个人登录地址
  let sso_uri = 'https://personal.sso.iwonderful.top:8080'
  let url = ''
  if(process.env.VUE_APP_MODE === 'DEV') {
    url = `${sso_uri}/authorize?response_type=code&scope=openid&client_id=userCenter&redirect_uri=${redirect_uri}&state=/`
  } else {
    // 生产重定向前端地址
    redirect_uri = 'https://cloud-platform.acassets.tech/sso'
    // 生产重定向SSO个人登录地址
    sso_uri = 'https://personal.sso.cloud-platform.acassets.tech'
    url = `${sso_uri}/authorize?response_type=code&scope=openid&client_id=userCenter&redirect_uri=${redirect_uri}&state=/`
  }
  return {
    url: url,
    domain: sso_uri
  }
}

function getEnterPriseUrl() {
  // DEV重定向前端地址
  // DEV SSO企业端登录地址
  let redirect_uri = 'http://ac.cloud-platform-dev.web/sso'
  let sso_uri = 'https://enterprise.sso.iwonderful.top:8080'
  let url = ''
  if(process.env.VUE_APP_MODE === 'DEV') {
    url =  `${sso_uri}/authorize?response_type=code&scope=openid&client_id=userCenter&redirect_uri=${redirect_uri}&state=/`
  }else {
    // 生产重定向前端地址
    redirect_uri = 'https://cloud-platform.acassets.tech/sso'
    // 生产重定向SSO企业登录地址
    sso_uri = 'https://enterprise.sso.cloud-platform.acassets.tech'
    url = `${sso_uri}/authorize?response_type=code&scope=openid&client_id=userCenter&redirect_uri=${redirect_uri}&state=/`
  }
  return {
    url,
    domain: sso_uri
  }
}